import { Project } from './src/project';
import { Warranties } from './src/warranties';
import { ProductionReport } from './src/production_report';
import { BuildNotesEdit } from './src/build_notes_edit';
import { CompanyCam } from './src/company_cam';
import { ProjectReport } from './src/project_report';
import { AuthorizeNet } from './src/authorize_net';
import { CopyToInputButton } from './src/copy_to_input_button';
import { CalendarSetup } from './src/calendar';
import { RecurringSalesEvent } from './src/recurring_sales_event';
import { SalesDashboard } from './src/sales_dashboard';

require('babel-polyfill');
require('@nathanvda/cocoon');
require('bootstrap');
require('./src/scroll_page_height');
require('./src/pages/index');
require('./src/pages/projects_form_page');
import 'chartkick/chart.js';

$(document).ready(function() {
  Project.setup();
  ProductionReport.setup();
  Warranties.setup();
  BuildNotesEdit.setup();
  CompanyCam.setup();
  ProjectReport.setup();
  AuthorizeNet.setup();
  CopyToInputButton.setup();
  CalendarSetup.setup();
  RecurringSalesEvent.setup();
  SalesDashboard.setup();
});
