import $ from 'jquery';
import mustache from 'mustache';
import swal from 'sweetalert';
import moment from 'moment';
import { Toast } from 'rad_common_js/src/toast';

export class RemindersModal {
  /**
   * Gets and displays the Reminders Modal window
   */
  static start (url) {
    $.ajax({
      type: 'GET',
      dataType: 'html',
      url: url,
      success: (data) => {
        const $data = $(data);
        $('body').prepend($data);
        this.setup();
        $('#js-reminders__index').modal({ backdrop: 'static' });
      }
    });
  }

  /**
   * Sets up the Reminders Modal window
   */
  static setup () {
    $('#js-reminders__index').on('click', '.js-reminders__delete', (event) => {
      event.preventDefault();
      event.stopPropagation();
      const link = $(event.currentTarget);
      const url = link.attr('href');
      const form = link.closest('form');
      swal({
        title: 'Are you sure?',
        text: 'This will permanently delete this item',
        icon: 'warning',
        buttons: true,
        dangerMode: true
      })
        .then((willDelete) => {
          if (willDelete) {
            $.ajax({
              type: 'DELETE',
              dataType: 'json',
              url: url,
              success: (data) => {
                Toast.success('Reminder deleted!', 'The reminder has been deleted.');
                form.fadeOut(300, () => {
                  $(form).remove();
                  document.querySelector(`#note_${data.note_id}_reminders`).dataset.reminderCount--;
                  this.handleReminderChange(data.note_id);
                });
              }
            });
          }
        });
    });

    const $addForm = $('#js-reminders__add-form');


    $addForm.on('ajax:success', (event, data) => {
      let note_reminder = event.detail[0];
      const $form = $(event.target);

      const template = $('.js-reminder-tmpl').html();
      note_reminder.date = moment(note_reminder.date).format('M/D/YYYY');
      const $newForm = $(mustache.render(template, { note_reminder: note_reminder })).insertBefore($form);

      $newForm
        .find(`[name="note_reminder[user_id]"] option[value="${note_reminder.user_id}"]`).prop('selected', true);
      $addForm.trigger('reset');
      $addForm.find('input[name="material[name]"]').focus();
      document.querySelector(`#note_${note_reminder.note_id}_reminders`).dataset.reminderCount++;
      this.handleReminderChange(note_reminder.note_id);

      Toast.success('Reminder added!', 'The reminder has been added.');
    });

    $addForm.on('ajax:error', () => Toast.error('Error!', 'There was an error adding the reminder.'));
  }

  static handleReminderChange(noteId) {
    const noteReminderButton = $(`#note_${noteId}_reminders span`);
    const reminderCount = document.querySelector(`#note_${noteId}_reminders`).dataset.reminderCount;
    if (reminderCount > 0) {
      noteReminderButton.removeClass('btn-secondary');
      noteReminderButton.addClass('btn-success');
    } else {
      noteReminderButton.removeClass('btn-success');
      noteReminderButton.addClass('btn-secondary');
    }
    noteReminderButton.text(`Reminders (${reminderCount})`);
  }
}
