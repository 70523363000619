import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'warrantyInput',
    'shingleMaterialInput',
    'ridgeCapMaterialInput',
    'valleyMaterialInput',
    'dripEdgeMaterialInput',
    'nailsMaterialInput',
    'underlaymentMaterialInput',
    'starterMaterialInput',
    'modifiedMaterialInput'
  ];

  onWarrantyChange() {
    const selectedOption = this.warrantyInputTarget.options[this.warrantyInputTarget.selectedIndex];
    if (!selectedOption) return;

    const materialMapping = {
      shingleMaterialId: this.shingleMaterialInputTarget,
      ridgeCapMaterialId: this.ridgeCapMaterialInputTarget,
      valleyMaterialId: this.valleyMaterialInputTarget,
      dripEdgeMaterialId: this.dripEdgeMaterialInputTarget,
      nailsMaterialId: this.nailsMaterialInputTarget,
      underlaymentMaterialId: this.underlaymentMaterialInputTarget,
      starterMaterialId: this.starterMaterialInputTarget,
      modifiedMaterialId: this.modifiedMaterialInputTarget
    };

    Object.entries(materialMapping).forEach(([datasetKey, targetElement]) => {
      if (selectedOption.dataset[datasetKey]) {
        targetElement.value = selectedOption.dataset[datasetKey];
      }
    });
  }
}
