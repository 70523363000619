// https://nandovieira.com/using-es6-with-asset-pipeline-on-ruby-on-rails
import $ from 'jquery';
import _ from 'lodash';
import { AddonsIndexPage } from './addons_index_page';
import { BasePage } from './base_page';
import { EmailsIndexPage } from './emails_index_page';
import { EstimatesFormPage } from './estimates_form_page';
import { NotesIndexPage } from './notes_index_page';
import { OnlineFormsEditPage } from './online_forms_edit_page';
import { OnlineFormsSubmittedPage } from './online_forms_submitted_page';
import { ProjectsEditPage } from './projects_edit_page';
import { SalesEventsFormPage } from './sales_events_form_page';
import { ReportsPostBuildPage } from './reports_post_build_page';
import { WorkOrdersFormPage } from './work_orders_form_page';
import { WorkOrdersIndexPage } from './work_orders_index_page';

let Pages = {};

Pages['AddonsIndexPage'] = AddonsIndexPage;
Pages['BasePage'] = BasePage;
Pages['EmailsIndexPage'] = EmailsIndexPage;
Pages['EstimatesFormPage'] = EstimatesFormPage;
Pages['NotesIndexPage'] = NotesIndexPage;
Pages['OnlineFormsEditPage'] = OnlineFormsEditPage;
Pages['OnlineFormsSubmittedPage'] = OnlineFormsSubmittedPage;
Pages['ProjectsEditPage'] = ProjectsEditPage;
Pages['SalesEventsFormPage'] = SalesEventsFormPage;
Pages['ReportsPostBuildPage'] = ReportsPostBuildPage;
Pages['WorkOrdersFormPage'] = WorkOrdersFormPage;
Pages['WorkOrdersIndexPage'] = WorkOrdersIndexPage;

const runner = () => {
  const header = $('#page-header');

  // All scripts must live in app/assets/javascripts/application/pages/**/*.js.
  const controller = _.startCase(header.data('controller')).replace(/ /g, '');
  const action = _.startCase(header.data('action')).replace(/ /g, '');

  let module = loadPageComponent(controller, action);

  if (module) {
    onload(module);
  }
};

/**
 * @param {BasePage} Page.
 */
const onload = (Page) => {
  // Instantiate the page, passing <body> as the root element.
  var page = new Page($(document.body));

  // Set up page and run scripts for it.
  if (page.setup) {
    page.setup();
  }

  page.run();
};

const loadPageComponent = (controller, action) => {
  const actions = [action];

  if (['New', 'Edit', 'Update', 'Create'].includes(action)) {
    // This is a way to share common JS behaviour for both views, edit and new.
    actions.push('Form');
  }

  const foundAction = actions.find((action) => Pages[`${controller}${action}Page`]);

  return Pages[`${controller}${foundAction}Page`] || Pages[`${controller}Page`];
};

$(document).ready(() => {
  runner();
});
