import $ from 'jquery';
import { Controller } from '@hotwired/stimulus';
import mapboxgl from 'mapbox-gl';
import mapboxSdk from '@mapbox/mapbox-sdk';
import geocoding from '@mapbox/mapbox-sdk/services/geocoding';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { point as turfPoint, polygon as turfPolygon } from '@turf/helpers';
import { area as turfArea } from '@turf/area';
import { booleanPointInPolygon } from '@turf/boolean-point-in-polygon';
import { length as turfLength } from '@turf/length';
import { calculateSubTotals } from '../../src/pages/estimates_form_page';

export default class extends Controller {
  static targets = [
    'container',
    'mapCenterInput',
    'areaOutput',
    'areaCoordinatesInput',
    'mapTypeButton',
    'areaSquaresDisplay',
    'hipDisplay',
    'rakeDisplay',
    'ridgeDisplay',
    'valleyDisplay',
    'eavesDisplay',
    'roofTypeInput',
    'pitchInput',
    'mapRoofTypeInput',
    'mapPitchInput',
    'mapDataInput',
    'errorOutput',
    'flatSquaresDisplay',
    'flatEavesDisplay',
    'modalHeader',
    'calcButton',
    'pitchedSquaresInput',
    'customMeasurementsButton',
    'modal'
  ];
  static values = { address: String, apiKey: String, mapData: Object };

  connect() {
    if (!this.hasMapDataValue) {
      this.mapDataValue = {
        center: [],
        coordinates: [],
        area_squares: 0,
        flat_squares: 0,
        flat_coordinates: [],
        flat_eaves_lf: 0,
        report_fields: {}
      };
    }
    this.updateMapDisplayFields();
    this.toggleMapLockedFields();
  }

  async togglePitchedMap() {
    this.mapType = 'pitched';
    this.mapPitchInputTarget.parentElement.classList.remove('d-none');
    this.modalHeaderTarget.textContent = 'Steep Slope Roof Map';
    this.calcButtonTarget.classList.remove('btn-info');
    this.calcButtonTarget.classList.add('btn-primary');
    this.mapRoofTypeInputTarget.parentElement.classList.remove('d-none');
    await this.toggleMap();
  }

  async toggleFlatMap() {
    this.mapType = 'flat';
    this.mapPitchInputTarget.parentElement.classList.add('d-none');
    this.modalHeaderTarget.textContent = 'Low Slope Roof Map';
    this.calcButtonTarget.classList.remove('btn-primary');
    this.calcButtonTarget.classList.add('btn-info');
    this.mapRoofTypeInputTarget.parentElement.classList.add('d-none');
    await this.toggleMap();
  }

  async toggleMap() {  
    if (!this.mapInitialized) {
      await this.initializeMap();
    }

    if (this.pitchInputTarget.value) {
      this.mapPitchInputTarget.value = this.pitchInputTarget.value;
    }

    if (this.roofTypeInputTarget) {
      this.mapRoofTypeInputTarget.value = this.roofTypeInputTarget.value;
    }

    $(this.modalTarget).modal('show');

    $(this.modalTarget).on('shown.bs.modal', () => {
      this.map.resize();
    });
  }

  async initializeMap() {
    const address = this.addressValue;
    if (!address) return;

    mapboxgl.accessToken = this.apiKeyValue;
    const mapboxClient = mapboxSdk({ accessToken: this.apiKeyValue });
    let center = await this.determineCenter(mapboxClient, address);

    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v12',
      center,
      zoom: 18,
    });

    await this.waitForMapStyle(map);

    const draw = this.initializeMapControls(map);
    this.initializeMapTypeToggle(map);
    this.setupFullscreenFix(map);
    this.handleMapLoad(map, draw, center);
    this.map = map;
  }

  async determineCenter(mapboxClient, address) {
    const existingCenter = this.mapDataValue.center;
    if (Array.isArray(existingCenter) && existingCenter.length === 2) {
      return existingCenter;
    }

    const geocodingService = geocoding(mapboxClient);
    const geocodeResult = await geocodingService
      .forwardGeocode({ query: address, autocomplete: false, limit: 1 })
      .send();
    const center = geocodeResult.body.features[0].center;
    this.mapDataValue = { ...this.mapDataValue, center: center };
    return center;
  }

  waitForMapStyle(map) {
    return new Promise((resolve) => map.once('style.load', resolve));
  }

  initializeMapControls(map) {
    const draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: { polygon: true, trash: true }
    });

    map.addControl(draw, 'top-left');
    map.addControl(new mapboxgl.FullscreenControl());
    map.addControl(new mapboxgl.NavigationControl());
    return draw;
  }

  setupFullscreenFix(map) {
    document.addEventListener('fullscreenchange', () => map.resize());
  }

  initializeMapTypeToggle(map) {
    this.mapTypeButtonTargets.forEach((button) => {
      button.addEventListener('click', (event) => {
        this.mapTypeButtonTargets.forEach((el) => el.classList.remove('active'));
        event.currentTarget.classList.add('active');
        const mapType = event.currentTarget.firstChild.value;
        map.setStyle(
          mapType === 'satellite'
            ? 'mapbox://styles/mapbox/satellite-v9'
            : 'mapbox://styles/mapbox/streets-v12'
        );
        const marker = document.querySelector('.mapboxgl-marker');
        marker?.classList?.toggle('d-none', mapType === 'satellite');
      });
    });
  }

  handleMapLoad(map, draw, center) {
    map.on('load', () => {
      this.setupExistingPolygon(map, draw);
      this.addDraggableMarker(map, center, draw);
      map.flyTo({ zoom: 20 });
    });

    map.on('draw.update', (event) => {
      const coords = event.features[0].geometry.coordinates;
      const polygon = turfPolygon(coords);
      this.calculateArea(polygon);
    });
  }

  setupExistingPolygon(map, draw) {
    const existingPolygon = this.mapType == 'flat' ? this.mapDataValue.flat_coordinates : this.mapDataValue.coordinates;
    if (existingPolygon && existingPolygon.length) {
      let polygon;
      try {
        polygon = turfPolygon(existingPolygon);
      } catch {
        polygon = turfPolygon([existingPolygon]);
      }

      this.calculateArea(polygon);
      this.drawPolygon(map, draw, polygon.geometry.coordinates);
    } else {
      this.handleCenterChange(map, draw, Object.values(map.getCenter()));
    }
  }

  addDraggableMarker(map, center, draw) {
    const marker = new mapboxgl.Marker({
      draggable: true,
      color: 'blue',
    })
      .setLngLat(center)
      .addTo(map);

    marker.on('dragstart', () => {
      this.areaOutputTarget.textContent = 'Calculating...';
    });

    marker.on('dragend', () => {
      const markerCoords = Object.values(marker.getLngLat());
      this.handleCenterChange(map, draw, markerCoords);
      this.mapDataValue = { ...this.mapDataValue, center: markerCoords };
      map.flyTo({ zoom: 20, center: markerCoords });
    });
  }

  calculateArea(polygon) {
    const area = turfArea(polygon);
    const areaSqFt = Math.round(area * 10.7639 * 100) / 100;
  
    if (this.mapType === 'flat') {
      const perimeter = turfLength(polygon, { units: 'feet' });
      this.mapDataValue = {
        ...this.mapDataValue,
        flat_squares: (Math.round(area * 10.7639) / 100),
        flat_coordinates: polygon.geometry.coordinates,
        flat_eaves_lf: Math.round(perimeter),
      };
    } else {
      this.mapDataValue = {
        ...this.mapDataValue,
        area_squares: (Math.round(area * 10.7639) / 100),
        coordinates: polygon.geometry.coordinates,
      };
    }

    this.areaOutputTarget.textContent = `${areaSqFt.toLocaleString()} Square Feet`;
  }

  drawPolygon(map, draw, coords) {
    map.getLayer('outline') && map.removeLayer('outline');
    map.getLayer('structure') &&
      map.removeLayer('structure') &&
      map.removeSource('draw') &&
      draw.deleteAll();

    map.addSource('draw', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });

    draw.add({
      type: 'Feature',
      geometry: { type: 'Polygon', coordinates: coords },
      properties: { id: 'structure' },
    });

    map.addLayer({
      id: 'structure',
      type: 'fill',
      source: 'draw',
      paint: { 'fill-color': '#0080ff', 'fill-opacity': 0.5 },
    });

    map.addLayer({
      id: 'outline',
      type: 'line',
      source: 'draw',
      paint: { 'line-color': '#000', 'line-width': 3 },
    });
  }

  handleCenterChange(map, draw, center) {
    const features = map.queryRenderedFeatures({
      layers: ['building'],
      filter: ['==', 'extrude', 'true'],
    });
    const point = turfPoint(center);
    const closestMatch = features.length === 1 ? features[0] :
      features.find((feature) => booleanPointInPolygon(point, turfPolygon(feature.geometry.coordinates)));

    if (!closestMatch) {
      this.mapDataValue = { ...this.mapDataValue, area_squares: 0, coordinates: [] };
      return;
    }

    const coords = closestMatch.geometry.coordinates;
    const polygon = turfPolygon(coords);
    this.calculateArea(polygon);
    this.drawPolygon(map, draw, coords);
  }

  calculateFields() {
    if (this.mapType === 'flat') {
      this.updateHiddenMapData();
      this.updateFlatDisplayFields({ updateSquares: true });
      $(this.modalTarget).modal('hide');
      return;
    }
      
    this.errorOutputTarget.classList.add('d-none');
    fetch(`/estimate_measurements?format=json&area_squares=${this.mapDataValue.area_squares}&roof_type=${this.mapRoofTypeInputTarget.value}&pitch=${this.mapPitchInputTarget.value}`)
      .then(response => response.json())
      .then(data => {
        this.mapDataValue = { ...this.mapDataValue, report_fields: data, locked: true };
        if (Object.values(data).map(Number).some(i => i)) {
          this.updateMapDisplayFields({ addFieldBorders: true, updateSquares: true });
          this.updateHiddenMapData();
          this.toggleMapLockedFields();
          $(this.modalTarget).modal('hide');
        } else {
          this.errorOutputTarget.classList.remove('d-none');
        }
      });
  }

  updateHiddenMapData() {
    this.mapDataInputTarget.value = JSON.stringify(this.mapDataValue);
  }

  updateMapDisplayFields({ updateSquares = false, addFieldBorders = false } = {}) {
    if (this.hasAreaSquaresDisplayTarget && this.mapDataValue.area_squares) {
      const squares = this.mapDataValue.report_fields.pitched_squares || this.mapDataValue.area_squares;
      if (updateSquares) {
        const areaInput = document.getElementById('estimate_pitched_squares');
        areaInput.value = squares;
        if (addFieldBorders) {
          areaInput.classList.add('border-primary');
        }
      }
      this.areaSquaresDisplayTarget.textContent = `Map: ${squares} SQ`;
    }

    if (this.mapRoofTypeInputTarget.value) {
      this.roofTypeInputTarget.value = this.mapRoofTypeInputTarget.value;
    }

    if (this.mapPitchInputTarget.value) {
      this.pitchInputTarget.value = this.mapPitchInputTarget.value;
    }

    if (document.getElementById('estimate_subtotal_pitched_squares') && updateSquares) {
      calculateSubTotals();
    }

    this.updateFlatDisplayFields(updateSquares);

    if (!this.mapDataValue.report_fields) return;

    this.updateDisplayField('ridge', 'ridge_lf', 'estimate_ridge_lf', ' LF', addFieldBorders, updateSquares);
    this.updateDisplayField('hip', 'hip_lf', 'estimate_hip_lf', ' LF', addFieldBorders, updateSquares);
    this.updateDisplayField('rake', 'rake_lf', 'estimate_rake_lf', ' LF', addFieldBorders, updateSquares);
    this.updateDisplayField('valley', 'valley_lf', 'estimate_valley_lf', ' LF', addFieldBorders, updateSquares);
    this.updateDisplayField('eaves', 'pitched_eaves_lf', 'estimate_pitched_eaves_lf', ' LF', addFieldBorders, updateSquares);
  }

  updateDisplayField(targetKey, fieldKey, inputId, suffix = '', addFieldBorders = false, updateSquares = false) {
    const displayTargetKey = `${targetKey}DisplayTarget`;
    if (this[displayTargetKey] && this.mapDataValue.report_fields[fieldKey]) {
      const inputElement = document.getElementById(inputId);
      if(updateSquares) {
        inputElement.value = this.mapDataValue.report_fields[fieldKey];
      }
      if (addFieldBorders) {
        inputElement.classList.add('border-primary');
      }
      this[displayTargetKey].textContent = `Map: ${this.mapDataValue.report_fields[fieldKey]}${suffix}`;
    }
  }

  updateFlatDisplayFields(updateSquares = false) {
    if (this.flatSquaresDisplayTarget && this.mapDataValue.flat_squares) {
      const flatSquaresInput = document.getElementById('estimate_flat_squares');
      if (updateSquares) {
        flatSquaresInput.value = this.mapDataValue.flat_squares;
        flatSquaresInput.classList.add('border-info');
      }
      this.flatSquaresDisplayTarget.textContent = `Map: ${this.mapDataValue.flat_squares} SQ`;
    }
  
    if (this.flatEavesDisplayTarget && this.mapDataValue.flat_eaves_lf) {
      const flatEavesInput = document.getElementById('estimate_flat_eaves_lf');
      if (updateSquares) {
        flatEavesInput.value = this.mapDataValue.flat_eaves_lf;
        flatEavesInput.classList.add('border-info');
      }
      this.flatEavesDisplayTarget.textContent = `Map: ${this.mapDataValue.flat_eaves_lf} LF`;
    }
  }

  toggleCustomMeasurements() {
    this.mapDataValue = { ...this.mapDataValue, locked: false };
    this.toggleMapLockedFields();
  }

  toggleMapLockedFields() {
    const disabled = this.mapDataValue.locked;
    this.pitchInputTarget.disabled = disabled;
    this.roofTypeInputTarget.disabled = disabled;
    this.pitchedSquaresInputTarget.readOnly = disabled;
    if (!this.hasCustomMeasurementsButtonTarget) return;

    if (disabled) {
      this.customMeasurementsButtonTarget.classList.remove('d-none');
    } else {
      this.customMeasurementsButtonTarget.classList.add('d-none');
    }
  }
}
