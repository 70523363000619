import $ from 'jquery';

$.fn.scrollPageHeight = function () {
  $(this).each((i, div) => {
    const $div = $(div);
    const top = $div.offset().top;
    const height = $(window).height() - top;
    $div.height(height);
  });
};

$(document).ready(() => {
  $('.js-scroll-page-height').scrollPageHeight();
});

$(window).resize(() => {
  $('.js-scroll-page-height').scrollPageHeight();
});
